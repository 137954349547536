import { useEffect, useState } from "react";

import { getChatById } from "../api/chat";
import { getOppositeMessenger } from "../helpers/util";

function useChat(chatId, userRole) {
  const [chat, setChat] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!chatId) return;
    const unsubscribe = getChatById(chatId, setLoading, setChat, setError);
    return unsubscribe;
  }, [chatId]);
  

  const oppositeMessenger = getOppositeMessenger(chat, userRole);
  return { isLoading, chat, error, oppositeMessenger };
}

export default useChat;
