import React from "react";
import cross from "../../../../src/assets/images/closeSideMenu.svg";
import clsx from "clsx";

// import { useNavigate } from "react-router-dom";
// import { useSubscription } from "../../../../shared/provider/SubscriptionProvider";
// import { STARTUP } from "../../../../shared/helpers/constants";

const PublicDeleteMessageModal = ({ closeModalFn, successFn }) => {
  return (
    <div className="modal-card md:!max-w-[22rem] p-0 public-message-modal">
      <button
        type="button"
        className="absolute top-0 right-1 text-gray-400 mt-1 mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0 maxMd:top-1 maxMd:right-1 maxMd:m-0"
        data-modal-toggle="staticModal"
        onClick={() => {
          closeModalFn();
        }}
      >
        <img src={cross} alt="cross" width={16} />
      </button>

      <div className=" overflow-y-auto">
        <div className="p-4">
          <h3 className=" font-bold text-lg maxMd:text-xl text-[#40425e] ">
            Are you sure you want to delete ?
          </h3>
          <p className="text-[14px] font-normal text-coolGrey text-left mt-3">
            This action cannot be undone. Once deleted the message will be
            permanently removed.
          </p>
          <div className=" flex justify-start items-center gap-4">
            <button
              onClick={() => successFn()}
              className={clsx(
                " flex justify-center items-center gap-2 font-medium text-base text-white bg-[#40425e] py-2 px-4 mt-4 rounded-md maxMd:text-5 cursor-pointer"
              )}
            >
              Delete
            </button>
            <button
              onClick={() => closeModalFn()}
              className={clsx(
                " flex justify-center items-center gap-2 font-medium text-base text-[#40425e] border-[#40425e] py-2 px-4 border-solid border mt-4 rounded-md maxMd:text-5 cursor-pointer"
              )}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicDeleteMessageModal;
