import React from "react";

import clsx from "clsx";
import { toast } from "react-toastify";
import MaskedInput from "react-text-mask";
import { useNavigate } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";

import Style from "../../../../../../assets/css/agent/auth.module.css";

import { useInvestorSignUpData } from "../../../../../../store/investorSignup";
import { propertyAlertSchema } from "../../../../../../modules/investors/auth/signup/validation";
import SelectYearBuilt from "../../../../../../modules/sellers/property-add/YearBuilt";

import {
  getUserCriteria,
  signup as investorSignUpApi,
} from "../../../../../../shared/api/investor/index";
import config from "../../../../../../shared/helpers/config";
import {
  currencyMask,
  unmaskNumbers,
} from "../../../../../../shared/helpers/util";
import ServerErrors from "../../../../../../shared/components/server-error";
import FormLabel from "../../../../../../shared/components/agent-form-control/FormLabel";
import FormButton from "../../../../../../shared/components/agent-form-control/FormButton";
import FormError from "../../../../../../shared/components/form-control/FormError";
import ReApiLocationPortal from "../../../../../../shared/components/re-api-dropdown/ReApiLocationPortal";

const initialAddressValue = {
  address: "",
  address_formatted: "",
  address_json: "",
  latitude: "",
  longitude: "",
};

const OnboardingStepTwoForm = ({ setStep, signupUserData }) => {
  const setInvestorSignUpData = useInvestorSignUpData(
    (state) => state.setInvestorSignUpData
  );
  const investorSignUpData = useInvestorSignUpData(
    (state) => state.investorSignUpData
  );
  const resetInvestorSignUpData = useInvestorSignUpData(
    (state) => state.resetInvestorSignUpData
  );
  let userCriteriaData;
  let userCriteriaDataAddresses;

  const navigate = useNavigate();

  const {
    mutate: mutateInvestorSignUp,
    isLoading,
    error,
    isError,
  } = useMutation((data) => investorSignUpApi(data), {
    onSuccess: () => {
      setStep(1);
      resetInvestorSignUpData();
      formik.handleReset();
      navigate("/buyers/login");
      toast.success("Email Sent! Verify your email to complete the setup.");
    },
    onError: (err) => {
      toast.error(err?.response.data.message);
    },
  });
  const { data } = useQuery(["get-user-criteria"], () => getUserCriteria(), {
    enabled: false,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  userCriteriaData = signupUserData?.property_criterias || data?.data?.data;
  userCriteriaDataAddresses =
    signupUserData?.property_criterias.addresses || data?.data?.data?.addresses;

  const formik = useFormik({
    initialValues: {
      type_of_property: userCriteriaData?.type_of_property || "",
      min_price: userCriteriaData?.min_price || "",
      max_price: userCriteriaData?.max_price || "",
      min_bed: userCriteriaData?.min_bed || "",
      max_bed: userCriteriaData?.max_bed || "",
      min_bath: userCriteriaData?.min_bath || "",
      max_bath: userCriteriaData?.max_bath || "",
      min_year_built: userCriteriaData?.min_year_built || "",
      max_year_built: userCriteriaData?.max_year_built || "",
      min_lot_size: userCriteriaData?.min_lot_size || "",
      max_lot_size: userCriteriaData?.max_lot_size || "",
      garage_type: userCriteriaData?.garage_type || "",
      hoa: userCriteriaData?.hoa || true,
      septic_system: userCriteriaData?.septic_system || true,
      pool: userCriteriaData?.pool || true,
      addresses: userCriteriaDataAddresses || [{ ...initialAddressValue }],
    },
    validationSchema: propertyAlertSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      // if (isEdit) {
      //   values["_method"] = "put";
      //   mutateCriteriaUpdate(values);
      // } else {
      const data = { ...investorSignUpData, ...values };
      setInvestorSignUpData(data);
      mutateInvestorSignUp(data);
    },
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, unmaskNumbers(value));
  };

  const onSwitchChange = (e, name) => {
    formik.setFieldValue(name, e.target.checked);
  };
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col form_signup investor_signup-form pb-10">
          <div>
            <div className="mb-2">
              <FormLabel title="Locations (Type City Name)" className={Style.formLabel} />
            </div>
            {/* location */}
            <div className="locationfields mb-2">
              <FieldArray
                name="addresses"
                render={(arrayHelpers) => (
                  <>
                    <div className="max-h-[220px] overflow-y-auto locationscroll flex flex-col pr-1">
                      {formik.values.addresses.map((data, index) => (
                        <div key={index} className="relative mb-2">
                          <ReApiLocationPortal
                            className="rounded-lg mb-2 border border-cloud form_control_input"
                            isError={
                              formik.errors.addresses &&
                              formik.errors.addresses[index]
                            }
                            placeholderName={"City Name"}
                            setFieldValue={formik.setFieldValue}
                            classNamePrefix={"profile_location--reapi"}
                            defaultvalue={data}
                            addressIndex={index}
                            removeLocation={(index) =>
                              arrayHelpers.remove(index)
                            }
                            searchType={["C"]}
                            showRemoveButton={
                              formik.values.addresses.length > 1
                            }
                          />
                          <div
                            className={"text-red-600 text-xs font-medium mt-1"}
                          >
                            {formik?.errors?.addresses?.[index]?.address ||
                              formik?.errors?.addresses?.[index]
                                ?.address_json ||
                              formik?.errors?.addresses?.[index]?.latitude ||
                              formik?.errors?.addresses?.[index]?.longitude}
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                      className="addlocationfield text-steelblue underline text-sm font-semibold mb-2.5"
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({ ...initialAddressValue })
                      }
                    >
                      Add Location
                    </button>
                  </>
                )}
              />
            </div>

            {/* type of property */}
            <div
              className={clsx(
                "maxMd:mb-3 mb-4 relative arrow_icon arrow_icon--down"
              )}
            >
              <FormLabel
                title="Type of Property"
                className={Style.formLabel}
                htmlFor="type_of_property"
              />
              <select
                id="type_of_property"
                name="type_of_property"
                className={clsx(Style.formInput, "!bg-transparent")}
                onChange={formik.handleChange}
                value={formik.values.type_of_property || ""}
              >
                <option value="">No Preference</option>
                {config?.type_of_property &&
                  Object.keys(config.type_of_property).map((key) => {
                    return (
                      <option key={key} value={config.type_of_property[key]}>
                        {config.type_of_property[key]}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className={clsx("maxMd:mb-3 mb-4 ")}>
              <FormLabel title=" Price Range" className={Style.formLabel} />

              <div className="form-group grid grid-cols-2 gap-8 ">
                <div className="relative">
                  <div className="!top-[25px]">
                    <MaskedInput
                      id="min_price"
                      className={clsx(Style.formInput, "!bg-transparent")}
                      placeholder="No Min"
                      name="min_price"
                      mask={currencyMask}
                      autoComplete="off"
                      value={formik.values.min_price || null}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="relative">
                  <div className="!top-[25px]">
                    <MaskedInput
                      id="max_price"
                      className={clsx(Style.formInput, "!bg-transparent")}
                      placeholder="No Max"
                      name="max_price"
                      mask={currencyMask}
                      autoComplete="off"
                      value={formik.values.max_price || null}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* bedrooms */}
            <div className={clsx("maxMd:mb-3 mb-4 relative")}>
              <FormLabel title="Bedrooms" className={Style.formLabel} />
              <div className="form-group grid grid-cols-2 gap-8">
                <div className="relative">
                  <div className=" arrow_iconsignup arrow_iconsignup--down ">
                    <select
                      id="min_bed"
                      name="min_bed"
                      className={clsx(Style.formInput, "!bg-transparent")}
                      onChange={formik.handleChange}
                      value={formik.values.min_bed || ""}
                    >
                      <option value="">No Min</option>
                      {config?.limit &&
                        Object?.keys(config.limit)?.map((key) => {
                          return (
                            <option key={key} value={key}>
                              {key}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="relative">
                  <div className=" arrow_iconsignup arrow_iconsignup--down">
                    <select
                      id="max_bed"
                      name="max_bed"
                      className={clsx(Style.formInput, "!bg-transparent")}
                      onChange={formik.handleChange}
                      value={formik.values.max_bed || ""}
                    >
                      <option value="">No Max</option>
                      {config?.limit &&
                        Object.keys(config.limit).map((key) => {
                          return (
                            <option key={key} value={key}>
                              {key}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* bathrooms */}
            <div className={clsx("maxMd:mb-3 mb-4")}>
              <FormLabel title="Baths" className={Style.formLabel} />

              <div className="form-group grid grid-cols-2 gap-8">
                <div className="relative">
                  <div className=" arrow_iconsignup arrow_iconsignup--down ">
                    <select
                      id="min_bath"
                      name="min_bath"
                      className={clsx(Style.formInput, "!bg-transparent")}
                      onChange={formik.handleChange}
                      value={formik.values.min_bath || ""}
                    >
                      <option value="">No Min</option>
                      {config?.limit &&
                        Object.keys(config.limit).map((key) => {
                          return (
                            <option key={key} value={key}>
                              {key}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="relative">
                  <div className=" arrow_iconsignup arrow_iconsignup--down">
                    <select
                      id="max_bath"
                      name="max_bath"
                      className={clsx(Style.formInput, "!bg-transparent")}
                      onChange={formik.handleChange}
                      value={formik.values.max_bath || ""}
                    >
                      <option value="">No Max</option>
                      {config?.limit &&
                        Object.keys(config.limit).map((key) => {
                          return (
                            <option key={key} value={key}>
                              {key}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col-reverse justify-between">
            <div>
              {isError && <ServerErrors errors={error} />}

              <FormButton
                type="submit"
                className={clsx(
                  Style.formButton,
                  "flex justify-center font-medium"
                )}
                title={"Complete"}
                loader={isLoading}
                disabled={isLoading}
              />
            </div>
            <div>
              {/* year built */}
              <div className={clsx("maxMd:mb-3 mb-4")}>
                <FormLabel title="Year Built" className={Style.formLabel} />
                <div className="form-group grid grid-cols-2 gap-8">
                  <div className="relative">
                    <div className="arrow_iconsignup arrow_iconsignup--down">
                      <SelectYearBuilt
                        name="min_year_built"
                        className={clsx(Style.formInput, "!bg-transparent", {
                          "is-error": formik.errors.min_year_built,
                        })}
                        defaultOption="No Min"
                        onChange={formik.handleChange}
                        value={formik.values.min_year_built || ""}
                      />
                      <FormError error={formik.errors.min_year_built} />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="arrow_iconsignup arrow_iconsignup--down">
                      <SelectYearBuilt
                        name="max_year_built"
                        className={clsx(Style.formInput, "!bg-transparent", {
                          "is-error": formik.errors.max_year_built,
                        })}
                        defaultOption="No Max"
                        onChange={formik.handleChange}
                        value={formik.values.max_year_built || ""}
                      />
                      <FormError error={formik.errors.max_year_built} />
                    </div>
                  </div>
                </div>
              </div>

              {/* lot size */}
              <div className={clsx("maxMd:mb-3 mb-4 ")}>
                <FormLabel title="Lot Size" className={Style.formLabel} />
                <div className="form-group grid grid-cols-2 gap-8">
                  <div className="relative">
                    <div className="arrow_iconsignup arrow_iconsignup--down">
                      <select
                        id="min_lot_size"
                        name="min_lot_size"
                        className={clsx(Style.formInput, "!bg-transparent")}
                        onChange={formik.handleChange}
                        value={formik.values.min_lot_size || ""}
                      >
                        <option value="">No Min</option>
                        {config?.lot_size &&
                          Object.keys(config.lot_size).map((key) => {
                            return (
                              <option key={key} value={config.lot_size[key]}>
                                {config.lot_size[key] !== 0.4
                                  ? config.lot_size[key]
                                  : "< 0.5"}{" "}
                                acre
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="  arrow_iconsignup arrow_iconsignup--down">
                      <select
                        id="max_lot_size"
                        name="max_lot_size"
                        className={clsx(Style.formInput, "!bg-transparent")}
                        onChange={formik.handleChange}
                        value={formik.values.max_lot_size || ""}
                      >
                        <option value="">No Max</option>
                        {config?.lot_size &&
                          Object.keys(config.lot_size).map((key) => {
                            return (
                              <option key={key} value={config.lot_size[key]}>
                                {config.lot_size[key] !== 0.4
                                  ? config.lot_size[key]
                                  : "< 0.5"}{" "}
                                acre
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* garage type */}
              <div className={clsx("maxMd:mb-3 mb-4 ")}>
                <FormLabel
                  title="Garage Type"
                  className={Style.formLabel}
                  htmlFor="garage_type"
                />
                <div className="relative">
                  <div className="arrow_iconsignup arrow_iconsignup--down">
                    <select
                      id="garage_type"
                      name="garage_type"
                      className={clsx(Style.formInput, "!bg-transparent")}
                      onChange={formik.handleChange}
                      value={formik.values.garage_type || ""}
                    >
                      <option value="">No Preference</option>
                      {config?.garage_type &&
                        Object.keys(config.garage_type).map((key) => {
                          return (
                            <option key={key} value={config.garage_type[key]}>
                              {config.garage_type[key]}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>

              {/* features */}
              <div className={clsx("maxMd:mb-3 mb-2")}>
                <FormLabel
                  title="Acceptable Features"
                  className={Style.formLabel}
                />
                <div className="flex items-center custom_toggle-switch justify-between gap-4 my-2 w-full flex-nowrap">
                  <div className="relative w-1/3 acceptable_checkbox">
                    <div>
                      <input
                        type="checkbox"
                        id="custom-checkbox-hoa"
                        name="hoa"
                        checked={formik.values.hoa}
                        onChange={(e) => onSwitchChange(e, "hoa")}
                        className="hidden"
                      />
                      <label
                        htmlFor="custom-checkbox-hoa"
                        className="inline-block cursor-pointer border border-lightsilver rounded-md w-full h-16 maxMd:h-12"
                      ></label>
                    </div>
                    <label
                      htmlFor="custom-checkbox-hoa"
                      className="acceptable_checkbox-label"
                    >
                      HOA
                    </label>
                  </div>
                  <div className="relative w-1/3 acceptable_checkbox">
                    <div>
                      <input
                        type="checkbox"
                        id="custom-checkbox-pool"
                        name="pool"
                        checked={formik.values.pool}
                        onChange={(e) => onSwitchChange(e, "pool")}
                        className="hidden"
                      />
                      <label
                        htmlFor="custom-checkbox-pool"
                        className="inline-block cursor-pointer border border-lightsilver rounded-md w-full h-16 maxMd:h-12"
                      ></label>
                    </div>
                    <label
                      htmlFor="custom-checkbox-pool"
                      className="acceptable_checkbox-label"
                    >
                      Pool
                    </label>
                  </div>
                  <div className="relative w-1/3 acceptable_checkbox">
                    <div>
                      <input
                        type="checkbox"
                        id="custom-checkbox-septic"
                        name="septic_system"
                        checked={formik.values.septic_system}
                        onChange={(e) => onSwitchChange(e, "septic_system")}
                        className="hidden"
                      />
                      <label
                        htmlFor="custom-checkbox-septic"
                        className="inline-block cursor-pointer border border-lightsilver rounded-md w-full h-16 maxMd:h-12"
                      ></label>
                    </div>
                    <label
                      htmlFor="custom-checkbox-septic"
                      className="acceptable_checkbox-label"
                    >
                      Septic
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default OnboardingStepTwoForm;