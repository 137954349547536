import React from "react";

const NeighbourhoodStatisticsInfo = ({ value, label }) => {
  return (
    <div className="mt-4">
      <p className="text-[#737373C7] text-base">{label}</p>
      <h2 className="text-davygrey lg:text-[28px] text-xl mt-1 font-inter-extra-bold">
        {value}
        <span className="text-[#2FA2B9] text-base ml-2 font-inter-regular">
          +5%
        </span>
      </h2>
    </div>
  );
};

export default NeighbourhoodStatisticsInfo;
