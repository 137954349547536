import React, { useCallback, useEffect, useRef } from "react";

import { v4 as uuidv4 } from "uuid";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import { sendImageMessage } from "../api/chat";
import { storage } from "../helpers/firebase";
import { isFileImage } from "../helpers/util";

function useUploadFile() {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const uploadTaskRef = useRef();

  useEffect(() => {
    return () => {
      if (uploadTaskRef.current) {
        uploadTaskRef.current.cancel();
      }
    };
  }, []);

  const reset = () => {
    setLoading(false);
    setProgress(0);
    setError(false);
  };

  const sendImage = useCallback((chatId, file, senderType) => {
    reset();
    if (!isFileImage(file)) {
      setLoading(false);
      setProgress(0);
      setError("File is not a valid image");
      return;
    }

    setLoading(true);
    const uploadTask = uploadBytesResumable(
      ref(storage, `${chatId}/${uuidv4()}`),
      file
    );

    uploadTaskRef.current = uploadTask;

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        setLoading(false);
        setError(error.message);
        setProgress(0);
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        setLoading(false);
        await sendImageMessage(chatId, url, senderType);
        setError(false);
        setProgress(0);
      }
    );
  }, []);

  return { isLoading, error, progress, sendImage };
}

export default useUploadFile;
