import clsx from "clsx";

import style from "../../../assets/css/homeowner/home.module.css";

import NeighbourhoodStatisticsInfo from "./NeighbourhoodStatisticsInfo";

import map from "../../../assets/images/home/map.png";

const NeighbourhoodStatistics = ({ isPublicRoute }) => {
  return (
    <div className={clsx(style.card, "p-4 flex gap-5 mt-5")}>
      <div>
        <img src={map} alt="map" className="w-full" />
      </div>
      <div className="w-full">
        <div className="flex flex-wrap">
          <h3 className={style.headingMd}>
            My neighborhood statistics this month
          </h3>
          <select className={clsx(style.selectMenu, "ml-auto")}>
            <option>Last 30 Days</option>
            <option>Last 3 Year</option>
            <option>Last 5 Year</option>
          </select>
        </div>
        <div className="grid xl:grid-cols-4 md:grid-cols-2 md:gap-8 gap-4">
          <NeighbourhoodStatisticsInfo
            label="Avg. List Price"
            value="$675,000"
          />
          <NeighbourhoodStatisticsInfo
            label="Avg. Sales Price"
            value="$681,000"
          />
          <NeighbourhoodStatisticsInfo label="Avg. Price/SqFt" value="203" />
          <NeighbourhoodStatisticsInfo
            label="Average Days on Market"
            value="$32"
          />
        </div>
      </div>
    </div>
  );
};

export default NeighbourhoodStatistics;
