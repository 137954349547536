import { useCallback, useState } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import Loader from "../../../shared/components/loader/Loader";
import ModalPortal from "../../../shared/components/react-modal/ModalPortal";
import HomeDetail from "../../../shared/components/homeowner-requests-details/HomeDetail";
import EstimatedEquity from "../../../shared/components/homeowner-requests-details/EstimatedEquity";
import {
  getHODetailPublic,
  getHOPropertyDetailPublic,
} from "../../../shared/api/homehub";

import EstimatedValue from "../../../shared/components/homeowner-requests-details/EstimatedValue";
import MortgageRate from "../../../shared/components/homeowner-requests-details/MortgageRate";
import CashOutEquity from "../../../shared/components/homeowner-requests-details/CashOutEquity";
import NeighbourhoodStatistics from "../../../shared/components/homeowner-requests-details/NeighbourhoodStatistics";
import SellYourHome from "../../../shared/components/homeowner-requests-details/SellYourHome";
import MessageModal from "../../../shared/components/homeowner-requests-details/models/MessageModal";
import PropertiesRequestModal from "../../../shared/components/homeowner-requests-details/models/PropertiesRequestModal";
import SentRequestModal from "../../../shared/components/homeowner-requests-details/models/SentRequestModal";
import MortgageRatesModal from "../../../shared/components/homeowner-requests-details/models/MortgageRatesModal";
import clsx from "clsx";
import RequestHomeValuationModal from "../../../home-owner/common-dashboard-modals/RequestHomeValuationModal";
import InterestRatesModal from "./models/InterestRatesModal";
import LowerMonthlyPayment from "./LowerMonthlyPayment";
import { HO_PROPERTY_REQUEST_TYPE_CMA } from "../../helpers/constants";
import PublicContactCard from "./PublicContactCard";

const HOPropertyDetailPublic = () => {
  const { id: propertyDetailId, unique_hash } = useParams();

  // const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // const params = new URLSearchParams(location.search);
  // const homeownerIdFromParams = params.get("home_owner_id");

  const mortgageTargetReached = searchParams.has("mortgage-target-reached");

  const [isOpenPropertiesRequestModal, setIsOpenPropertiesRequestModal] =
    useState(false);
  const [mortgageRateModal, setMortgageRateModal] = useState(
    mortgageTargetReached
  );

  const [requestType, setRequestType] = useState(null);
  const [messageModal, setMessageModal] = useState(false);
  const [isSentRequestModalOpen, setIsSentRequestModalOpen] = useState(false);
  const [isOpenRequestHomeValuationModal, setIsOpenRequestHomeValuationModal] =
    useState(false);

  const [isMortgageRateModalOpen, setIsMortgageRateModalOpen] = useState(false);

  const {
    data: propertyData,
    isLoading: isLoadingPropertyData,
    refetch: propertyDataRefetch,
  } = useQuery(
    ["get-ho-property-detail", propertyDetailId, unique_hash],
    () => getHOPropertyDetailPublic(propertyDetailId, unique_hash),
    {
      enabled: !!propertyDetailId,
      select: (res) => {
        return res.data.data;
      },
      onError: (err) => {
        navigate("/properties");
      },
    }
  );

  const { data: homehubData } = useQuery(
    ["get-ho-detail", unique_hash],
    () => getHODetailPublic(unique_hash),
    {
      enabled: !!unique_hash,
      select: (res) => {
        return res.data;
      },
      onError: (err) => {},
    }
  );

  const onCloseInterestRatesModal = useCallback(() => {
    setIsMortgageRateModalOpen(false);
  }, []);

  const onClickInterestRatesModal = useCallback(() => {
    setIsMortgageRateModalOpen(true);
  }, []);

  const onClickRequestHomeValuation = useCallback(() => {
    setIsOpenRequestHomeValuationModal(true);
  }, []);

  const onCloseRequestHomeValuationModal = useCallback(() => {
    setIsOpenRequestHomeValuationModal(false);
  }, []);

  const onClosePropertiesRequestModal = useCallback(() => {
    setIsOpenPropertiesRequestModal(false);
  }, []);

  const onCloseSentRequestModal = useCallback(() => {
    setIsSentRequestModalOpen(false);
  }, []);

  if (isLoadingPropertyData) {
    return (
      <Loader className="h-screen flex justify-center items-center loader-content" />
    );
  }

  return (
    <>
      <section
        className={clsx("relative p-8 ", {
          "overflow-hidden h-screen":
            isOpenPropertiesRequestModal ||
            isSentRequestModalOpen ||
            messageModal ||
            mortgageRateModal ||
            isOpenRequestHomeValuationModal ||
            isMortgageRateModalOpen,
        })}
      >
        <div className="flex items-center flex-wrap mb-3">
          <h1 className="md:text-2xl text-lg text-[#4F555A] font-semibold">
            My Home’s Dashboard
          </h1>
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 gap-5">
          <HomeDetail
            propertyData={propertyData}
            propertyDataRefetch={propertyDataRefetch}
          />
          <EstimatedEquity
            propertyData={propertyData}
            onClickRequestHomeValuation={onClickRequestHomeValuation}
          />
          <EstimatedValue
            setIsOpenPropertiesRequestModal={setIsOpenPropertiesRequestModal}
            setRequestType={setRequestType}
            propertyData={propertyData}
          />
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 gap-5 mt-5">
          <MortgageRate
            propertyData={propertyData}
            propertyDataRefetch={propertyDataRefetch}
            isPublicRoute={true}
            onClickInterestRatesModal={onClickInterestRatesModal}
          />
          <div className="lg:col-span-2 grid md:grid-cols-2 gap-5">
            <LowerMonthlyPayment
              propertyData={propertyData}
              isPublicRoute={true}
            />

            <CashOutEquity
              setIsOpenPropertiesRequestModal={setIsOpenPropertiesRequestModal}
              setRequestType={setRequestType}
              propertyData={propertyData}
            />
          </div>
          <PublicContactCard
            propertyData={propertyData}
            setMessageModal={setMessageModal}
            isPublicRoute={true}
            homehubData={homehubData}
          />
        </div>
        <NeighbourhoodStatistics isPublicRoute={true} />
        <SellYourHome
          setRequestType={setRequestType}
          setIsOpenPropertiesRequestModal={setIsOpenPropertiesRequestModal}
        />
      </section>

      {/* property detail modals */}
      <ModalPortal open={isOpenPropertiesRequestModal}>
        <PropertiesRequestModal
          onClosePropertiesRequestModal={onClosePropertiesRequestModal}
          setIsSentRequestModalOpen={setIsSentRequestModalOpen}
          requestType={requestType}
          unique_hash={unique_hash}
        />
      </ModalPortal>
      <ModalPortal open={isSentRequestModalOpen}>
        <SentRequestModal
          onCloseSentRequestModal={onCloseSentRequestModal}
          requestType={HO_PROPERTY_REQUEST_TYPE_CMA}
        />
      </ModalPortal>
      <ModalPortal open={messageModal}>
        <MessageModal
          setMessageModal={setMessageModal}
          assigneeData={propertyData?.agent ?? propertyData?.admin}
        />
      </ModalPortal>
      <ModalPortal open={mortgageRateModal}>
        <MortgageRatesModal
          propertyData={propertyData}
          setMortgageRateModal={setMortgageRateModal}
          assigneeData={propertyData?.agent ?? propertyData?.admin}
        />
      </ModalPortal>
      <ModalPortal open={isOpenRequestHomeValuationModal}>
        <RequestHomeValuationModal
          onCloseRequestHomeValuationModal={onCloseRequestHomeValuationModal}
          requestType={HO_PROPERTY_REQUEST_TYPE_CMA}
          setIsSentRequestModalOpen={setIsSentRequestModalOpen}
          unique_hash={unique_hash}
        />
      </ModalPortal>
      <ModalPortal open={isMortgageRateModalOpen}>
        <InterestRatesModal
          onCloseInterestRatesModal={onCloseInterestRatesModal}
        />
      </ModalPortal>
    </>
  );
};

export default HOPropertyDetailPublic;
