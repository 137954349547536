import React, { useCallback, useEffect, useState } from "react";
import close from "../../../assets/images/close-icon.svg";

import { clsx } from "clsx";
import { useMediaQuery } from "usehooks-ts";

import styles from "../../../assets/css/chat-messages.module.css";

import { TYPE_IMAGE } from "../../helpers/constants";
import { svgLogo } from "../../api/data";
import { useChatMessage } from "../../provider/ChatMessageProvider";

import { includes } from "lodash";
import PublicDeleteMessageModal from "../confirmation-modal/PublicDeleteMessageModal";
import { createPortal } from "react-dom";
import ModalPortal from "../react-modal/ModalPortal";

const PublicMessage = ({
  message,
  isOwnMessage,
  time,
  type,
  messageId,
  isDelete,
  chatId,
  lastIndex,
}) => {
  const {
    handleMouseDown,
    handleMouseUp,
    handleMouseLeave,
    isIdPressed,
    onClickDeleteWeb,
    setLastMessageId,
    pressedItems,
    setPressedItems,
  } = useChatMessage();

  let mobile = useMediaQuery("(max-width: 1023px)");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setPressedItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDeleteButton = () => {
    setIsDeleteModalOpen(true);
  };

  const onDeleteMessage = () => {
    onClickDeleteWeb(chatId, messageId);
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    setLastMessageId(lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastIndex]);

  const headerChildrenRenderer = document.getElementById("chat-children");

  return (
    <>
      <div
        className={clsx(
          "sent_message py-1 flex justify-end items-center ",
          isIdPressed(messageId) && "!bg-[#1AC8DB33]"
        )}
        key={messageId}
        onTouchEnd={mobile ? handleMouseUp : undefined}
        onTouchCancel={mobile ? handleMouseLeave : undefined}
        onTouchStart={
          mobile
            ? () => handleMouseDown(isOwnMessage, isDelete, messageId)
            : undefined
        }
      >
        {!mobile && isOwnMessage && !isDelete && (
          <button
            className={clsx(
              "message_delete-icon hidden items-center gap-2 cursor-pointer",
              styles.delete_icon_button
            )}
            onClick={() => onClickDeleteButton()}
          >
            {svgLogo.deleIcon}
          </button>
        )}
        {includes(pressedItems, messageId) &&
          mobile &&
          isOwnMessage &&
          !isDelete && (
            <>
              <button
                className={clsx(
                  "message_delete-icon  items-center gap-2 cursor-pointer",
                  styles.delete_icon_button
                )}
                onClick={() => setPressedItems([])}
              >
                <img
                  src={close}
                  alt="close-img"
                  className=" w-[0.875rem] h-[0.875rem] mr-3"
                />
              </button>
              <button
                className={clsx(
                  "message_delete-icon  items-center gap-2 cursor-pointer",
                  styles.delete_icon_button
                )}
                onClick={() => onClickDeleteButton()}
              >
                {svgLogo.deleIcon}
              </button>
            </>
          )}

        <li
          className={clsx(
            isOwnMessage ? styles.msgSent : styles.msgReceive,
            "last_message relative",
            styles.delete_icon,
            "bg-[#40425e]",
            isDelete && "opacity-[0.8] !cursor-auto"
          )}
        >
          {type === TYPE_IMAGE ? (
            <a href={message} target="_blank" rel="noreferrer">
              <img
                src={message}
                className="object-contain h-48 w-96"
                alt={message}
              />
            </a>
          ) : (
            <span className={clsx(styles.message_word, isDelete && "!italic")}>
              {message}
            </span>
          )}
        </li>
      </div>

      <p
        className={clsx({
          "ml-3 mt-1": !isOwnMessage,
          "text-right mr-4 mt-1": isOwnMessage,
        })}
      >
        {time}
      </p>

      <ModalPortal open={isDeleteModalOpen}>
        <PublicDeleteMessageModal
          closeModalFn={onCloseDeleteModal}
          successFn={() => onDeleteMessage()}
        />
      </ModalPortal>
    </>
  );
};

export default PublicMessage;
