import React from "react";
import Login from "../../../../shared/auth/login/Login";
import { login, withoutTenantLogin } from "../../../../shared/api/agent";
import AgentsLogin from "../../../../shared/auth/login/AgentsLogin";
import {
  checkHostNameAndRedirect,
  isUpsideAppTenant,
} from "../../../../shared/helpers/util";

const AgentLogin = () => {
  // Function for condition check
  const shouldRenderLogin = () => {
    return (
      checkHostNameAndRedirect() || !isUpsideAppTenant(window.location.href)
    );
  };

  return (
    <>
      {shouldRenderLogin() ? (
        <Login
          onSubmit={login}
          forgotPasswordUrl="/agents/forgot-password"
          title="Agent Login"
        />
      ) : (
        <AgentsLogin
          onSubmit={withoutTenantLogin}
          forgotPasswordUrl="/agents/forgot-password"
          title="Agent Login"
        />
      )}
    </>
  );
};

export default AgentLogin;
