import {
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  where,
  limit,
  getDocsFromServer,
  updateDoc,
  doc,
  increment,
} from "firebase/firestore";
import {
  ADMIN,
  AGENT,
  HOMEOWNER,
  INVESTOR,
  SELLER,
  TYPE_IMAGE,
} from "../helpers/constants";
import { db, storage } from "../helpers/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

export const createChat = (user1, user2, entity1, entity2) =>
  addDoc(collection(db, "chats"), {
    [entity1]: user1,
    [entity2]: user2,
    timestamp: serverTimestamp(),
  });

export const sendMessage = async (chatId, message, senderType) => {
  await addDoc(collection(db, "chats", chatId, "messages"), {
    senderType,
    message,
    timestamp: serverTimestamp(),
  });

  await updateByChatId(chatId, {
    [`${senderType}.lastMessage`]: message,
    [`${senderType}.unreadCount`]: increment(1),
    [`${senderType}.is_reminded`]: false,
    [`${senderType}.lastMessageTimestamp`]: serverTimestamp(),
    [`${senderType}.isOnline`]: true,
    lastMessage: message,
    lastMessageTimestamp: serverTimestamp(),
  });
};

export const updateMessage = async (
  chatId,
  messageId,
  updateData,
  lastMessageId
) => {
  try {
    const messageRef = doc(db, "chats", chatId, "messages", messageId);

    // Exclude timestamp from updateData
    const { timestamp, ...dataToUpdate } = updateData;

    // Prepare the payload for updating the message
    const updatePayload = {
      ...dataToUpdate,
      isDelete: true,
      type: "message",
    };

    // Update the message
    await updateDoc(messageRef, updatePayload);

    // If the lastMessageId matches the current messageId, update the chat's last message
    if (lastMessageId === messageId) {
      const chatRef = doc(db, "chats", chatId);

      // Update the lastMessage field in the chat document
      await updateDoc(chatRef, {
        lastMessage: "Message was deleted",
        lastMessageTimestamp: serverTimestamp(),
      });
    }
    // Otherwise, only the message is updated and lastMessage remains unchanged
  } catch (error) {
    console.log(error);
    throw new Error("Error updating message");
  }
};

export const updateByChatId = (chatId, updateQuery) => {
  return updateDoc(doc(db, "chats", chatId), updateQuery);
};

export const getMessages = (
  chatId,
  loadingCallback,
  successCallback,
  failureCallback
) => {
  return onSnapshot(
    query(
      collection(db, "chats", chatId, "messages"),
      orderBy("timestamp", "asc")
    ),
    (querySnapshot) => {
      loadingCallback(false);
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      successCallback(messages);
    },
    (error) => {
      failureCallback(error.message);
    }
  );
};

export const getChat = (user1, user2, entity1, entity2) => {
  return getDocsFromServer(
    query(
      collection(db, "chats"),
      where(`${entity1}.id`, "==", user1.id),
      where(`${entity2}.id`, "==", user2.id),
      limit(1)
    )
  );
};

export const getChatById = (
  chatId,
  loadingCallback,
  successCallback,
  failureCallback
) => {
  return onSnapshot(
    query(doc(db, "chats", chatId)),
    (querySnapshot) => {
      loadingCallback(false);
      const data = querySnapshot.data() || {};
    
      successCallback({ id: querySnapshot.id, ...data });
    },
    (error) => {
      failureCallback(error.message);
    }
  );
};

export const updateOnlineStatus = (chatId, role, status) => {
  let field = "";
  switch (role) {
    case SELLER:
      field = "seller.isOnline";
      break;
    case INVESTOR:
      field = "investor.isOnline";
      break;
    case ADMIN:
      field = "admin.isOnline";
      break;
    case AGENT:
      field = "agent.isOnline";
      break;
    case HOMEOWNER:
      field = "homeowner.isOnline";
      break;
    default:
      break;
  }

  updateDoc(doc(db, "chats", chatId), {
    [field]: status,
  });
};

export const getChatList = (
  userId,
  userRole,
  loadingCallback,
  successCallback,
  failureCallback
) => {
  let column = "";
  let orderColumn = "lastMessageTimestamp";
  switch (userRole) {
    case INVESTOR:
      column = "investor.id";
      break;
    case SELLER:
      column = "seller.id";
      break;
    case ADMIN:
      column = "admin.id";
      break;
    case AGENT:
      column = "agent.id";
      break;
    case HOMEOWNER:
      column = "homeowner.id";
      break;
    default:
      break;
  }

  return onSnapshot(
    query(
      collection(db, "chats"),
      where(column, "==", userId),
      orderBy(orderColumn, "desc")
    ),
    (querySnapshot) => {
      loadingCallback(false);
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      successCallback(messages);
    },
    (error) => {
      failureCallback(error.message);
    }
  );
};

export const sendImageMessage = async (chatId, message, senderType) => {
  await addDoc(collection(db, "chats", chatId, "messages"), {
    senderType,
    message,
    timestamp: serverTimestamp(),
    type: TYPE_IMAGE,
  });

  updateByChatId(chatId, {
    [`${senderType}.lastMessage`]: "Image",
    [`${senderType}.unreadCount`]: increment(1),
    [`${senderType}.is_reminded`]: false,
    [`${senderType}.lastMessageTimestamp`]: serverTimestamp(),
    [`${senderType}.isOnline`]: true,
    lastMessage: "Image",
    lastMessageTimestamp: serverTimestamp(),
  });
};

export const sendImage = async (chatId, file, senderType) => {
  const res = await uploadBytes(ref(storage, `${chatId}/${uuidv4()}`), file);

  await addDoc(collection(db, "chats", chatId, "messages"), {
    senderType,
    message: await getDownloadURL(res.ref),
    timestamp: serverTimestamp(),
    type: TYPE_IMAGE,
  });

  updateByChatId(chatId, {
    [`${senderType}.lastMessage`]: "Image",
    [`${senderType}.unreadCount`]: increment(1),
    [`${senderType}.is_reminded`]: false,
    [`${senderType}.lastMessageTimestamp`]: serverTimestamp(),
    [`${senderType}.isOnline`]: true,
  });
};
