import React, { useRef, useState } from "react";

import { useOnClickOutside } from "usehooks-ts";
import EmojiPicker from "emoji-picker-react";

import styles from "../../../assets/css/chat-messages.module.css";

import { useChatMessage } from "../../provider/ChatMessageProvider";

import { sendMessage } from "../../api/chat";

import smileIcon from "../../../assets/images/smiley.svg";
import attachment from "../../../assets/images/attachment.svg";
import close from "../../../assets/images/plus.svg";
import location from "../../../assets/images/icons/location.svg";
import clsx from "clsx";

const PublicChatInput = ({ chatId, sendImage }) => {
  const [openEmoji, setEmoji] = useState(false);
  const { message, setMessage } = useChatMessage();
  const [focus, setFocus] = useState(false);

  const refEmoji = useRef(null);
  const ref = useRef(null);
  const imageRef = useRef(null);


  const handleClickOutside = () => {
    let selectInput = document.querySelector(".form-control").value;
    if (selectInput.length === 0) {
      setFocus(false);
    }
  };

  useOnClickOutside(ref, handleClickOutside);

  const emojiClickOutside = () => {
    setEmoji(false);
  };

  useOnClickOutside(refEmoji, emojiClickOutside);

  const onSend = async (e) => {
    e.preventDefault();

    if (!message) {
      return;
    }

    setEmoji(false);
    sendMessage(chatId, message, "homeowner");
    setMessage("");
  };
  const onEmojiClick = (e) => {
    setFocus(true);
    setMessage((_message) => `${_message} ${e.emoji}`);
  };

  const onFocus = () => {
    setFocus(true);
    setEmoji(false);
  };

  const onAttachmentClick = () => {
    imageRef.current?.click();
  };

  const onAttachmentSend = (e) => {
    e.preventDefault();

    if (e.target.files.length) {
      sendImage(chatId, e.target.files[0],  "homeowner");
      imageRef.current.value = null;
    }
  };

  return (
    <>
      <form onSubmit={onSend} className="relative   ">
        <div className={clsx(styles.msgFooter, "offerdetails_footer")}>
          {!focus && (
            <img className="w-5 h-5 opacity-30" src={close} alt="close-icon" />
          )}
          <div className="flex relative w-full">
            {openEmoji && (
              <div className="emoji-container" ref={refEmoji}>
                <EmojiPicker onEmojiClick={onEmojiClick} lazyLoad="true" />
              </div>
            )}

            <input
              className="form-control text-center !py-3.5 !pr-20 minMaxSm:!pr-10"
              type="text"
              placeholder="Write a reply..."
              onFocus={onFocus}
              ref={ref}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <input
              className="hidden"
              ref={imageRef}
              onChange={onAttachmentSend}
              type="file"
              accept="image/*"
            />

            <div className={styles.social_icon}>
              <button
                type="button"
                className="mr-2"
                onClick={() => setEmoji(true)}
              >
                <img
                  src={smileIcon}
                  alt="smileIcon"
                  className="w-5 h-5 object-contain"
                />
              </button>
              <button
                className="ml-1"
                type="button"
                onClick={onAttachmentClick}
              >
                <img
                  src={attachment}
                  alt="attachmentIcon"
                  className="w-5 h-5 object-contain"
                />
              </button>
            </div>
          </div>

          <button
            className="bg-jacarta px-[12px] py-[9px] ml-3 rounded-2xl "
            onClick={onSend}
            type="submit"
          >
            <img
              className="w-[22px] h-[22px]"
              src={location}
              alt="close-icon"
            />
          </button>
        </div>
      </form>
    </>
  );
};

export default PublicChatInput;
