import React, { useState } from "react";

import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "../../../assets/css/signup.module.css";

import { resetPasswordSchema } from "./validation";

import ServerErrors from "../../components/server-error";

import logo from "../../../assets/images/Logo3.png";
import Loader from "../../components/loader/Loader";
import view from "../../../assets/images/eye-off.svg";
import eye from "../../../assets/images/eye.svg";

const ResetPassword = ({ onSubmit, loginUrl, email }) => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const { mutate, isLoading, error, isError } = useMutation(
    (contact) => onSubmit(contact),
    {
      onSuccess: (res) => {
        toast.success(res.data.message);
        navigate(loginUrl);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      email: email || "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: resetPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      values["token"] = token;
      mutate(values);
    },
  });

  if (isLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }

  return (
    <>
      <section
        className={clsx(
          styles.custom_container,
          styles.custom_card,
          "h-screen"
        )}
      >
        <Link to="/">
          <img src={logo} alt="logo-img" className="mt-4 mb-8 w-44" />
        </Link>

        <div className={styles.card}>
          <div>
            <div className={styles.cardtext}>
              <h2 className="title_lg mb-2 text-[1.688rem] leading-[2.375rem]">
                Reset Password
              </h2>
            </div>
            <div>
              <form
                className={styles.inputRight}
                onSubmit={formik.handleSubmit}
              >
                <div className="maxMd:mb-3 mb-4">
                  <label className={styles.form_label}>Email</label>
                  <input
                    type="text"
                    className={clsx(
                      styles.form_control,
                      "email-disable !pr-0",
                      {
                        "border border-red-500 ": formik.errors.email,
                      }
                    )}
                    placeholder="Email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    disabled
                    readOnly
                  />
                </div>
                <div className={clsx(styles.view_icon, "maxMd:mb-3 mb-4")}>
                  <label className={styles.form_label}> New Password</label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className={clsx(styles.form_control, {
                      "border border-red-500": formik.errors.password,
                    })}
                    placeholder="New Password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  {!passwordShown && (
                    <button
                      type="button"
                      onClick={() => setPasswordShown(true)}
                    >
                      <img src={eye} alt="eye-icon" />
                    </button>
                  )}
                  {passwordShown && (
                    <button
                      type="button"
                      onClick={() => setPasswordShown(false)}
                    >
                      <img src={view} alt="view-icon" />
                    </button>
                  )}
                </div>
                <div className={clsx(styles.view_icon, "maxMd:mb-3 mb-4")}>
                  <label className={styles.form_label}>Confirm Password</label>
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    className={clsx(styles.form_control, {
                      "border border-red-500":
                        formik.errors.password_confirmation,
                    })}
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    onChange={formik.handleChange}
                    value={formik.values.password_confirmation}
                  />
                  {!confirmPasswordShown && (
                    <button
                      type="button"
                      onClick={() => setConfirmPasswordShown(true)}
                    >
                      <img src={eye} alt="eye-icon" />
                    </button>
                  )}
                  {confirmPasswordShown && (
                    <button
                      type="button"
                      onClick={() => setConfirmPasswordShown(false)}
                    >
                      <img src={view} alt="view-icon" />
                    </button>
                  )}
                </div>
                {isError && <ServerErrors errors={error} />}
                <button
                  type="submit"
                  className={clsx(
                    "btn btn-primary flex items-center justify-center maxMd:mt-2 mt-1 w-full py-6 hover:opacity-[0.8] transition-all",
                    { disabled: isLoading }
                  )}
                >
                  <span>Reset Password</span>
                  {isLoading && <Loader />}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
