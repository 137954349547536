import React, { useRef, useState } from "react";

import clsx from "clsx";
import ReactSelect from "react-select";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import style from "../../../assets/css/homeowner/home.module.css";

import {
  getMortgageRateDetails,
  updateMortgageRateDetails,
} from "../../../shared/api/homehub";
import Loader from "../../../shared/components/loader/Loader";
import { homehubPropertyDetailYearOptions } from "../../../shared/api/data";

import Switch from "./Switch";

const LowerMonthlyPayment = ({ propertyData, isPublicRoute }) => {
  const [years, setYears] = useState(null);
  const [percent, setPercent] = useState(null);
  const [alertSet, setAlertSet] = useState(false);

  const { id, unique_hash } = useParams();
  const percentRef = useRef(null);

  const mortgageRate = propertyData?.mortgage_rate;

  const { isLoading: isLoadingMortgageRate } = useQuery(
    ["get-mortgage-rate", id, unique_hash],
    () => getMortgageRateDetails(id, unique_hash),
    {
      enabled: !!id,
      cacheTime: 0,
      keepPreviousData: false,
      onSuccess: (res) => {
        setYears({ label: res.data.data.years, value: res.data.data.years });

        setPercent(
          res.data.data.percent
            ? res.data.data.percent
            : mortgageRate
            ? (mortgageRate - 1).toFixed(2)
            : null
        );
        setAlertSet(res.data.data.alert_set);
      },
    }
  );

  //handle percent
  const handlePercentChange = (e) => {
    const regex = /^(100(\.00?)?|\d{1,2}(\.\d{0,2})?)$/;
    const inputValue = e.target.value;

    if (inputValue === "" || regex.test(inputValue)) {
      setPercent(inputValue);
    }
  };

  const onPercentBlur = () => {
    if (!percent || percent === 0) return;
    updateMortgageRateDetails(id, { percent: percent });
  };

  //handle years
  const handleYearChange = (selected) => {
    if (!percent || Number(percent) === 0) {
      toast("Please add target percentage", { type: "error" });
      return;
    }
    setYears(selected);
    updateMortgageRateDetails(id, { years: selected.value });
  };

  //handle alert
  const handleAlertChange = () => {
    setAlertSet(!alertSet);
    updateMortgageRateDetails(id, { alert_set: !alertSet });
  };

  return (
    <div className={clsx("px-6 py-[1.15rem]", style.card)}>
      <h3 className={style.headingMd}>Lower my monthly payment</h3>
      <p className={clsx("my-2", style.description)}>
        Notify me to refinance when rates go down to my target rate.
      </p>
      {isLoadingMortgageRate && (
        <Loader className="flex justify-center items-center h-[30%]" />
      )}
      {!isLoadingMortgageRate && (
        <div className="flex gap-4 mt-[18px] mb-[30px]">
          <>
            <div className={clsx("max-w-[93px]", style.monthlyPayment)}>
              <input
                ref={percentRef}
                className="w-full px-2 py-1"
                value={
                  percent === null || percent === undefined ? "0" : percent
                }
                onChange={handlePercentChange}
                onBlur={onPercentBlur}
                disabled={isPublicRoute === true}
              />
              <span>%</span>
            </div>
            <div className={clsx("max-w-[96px]", style.monthlyPayment)}>
              <ReactSelect
                isSearchable={false}
                options={homehubPropertyDetailYearOptions}
                value={years}
                onChange={handleYearChange}
                defaultValue={years}
                className={"capitalize appearance-none"}
                classNamePrefix={"react-select_year"}
                isDisabled={isPublicRoute === true}
              />
              <span>Years</span>
            </div>
          </>
        </div>
      )}
      <div className="flex gap-3 items-start">
        <Switch
          checked={alertSet}
          onChange={handleAlertChange}
          isPublicRoute={isPublicRoute}
        />
        <p className={clsx("my-2", style.description)}>
          Turn on alerts to notify me when rates drop to my target rate so I can
          lower my monthly payment
        </p>
      </div>
    </div>
  );
};

export default LowerMonthlyPayment;
