import React, { useEffect } from "react";

import { updateOnlineStatus } from "../../api/chat";

const Online = ({ isOnline, chatId, role }) => {
  useEffect(() => {
    if (!chatId || !role) return;
    updateOnlineStatus(chatId, role, true);
    return () => {
      updateOnlineStatus(chatId, role, false);
    };
  }, [chatId, role]);

  useEffect(() => {
    if (!chatId || !role) return;

    const update = () => {
      updateOnlineStatus(chatId, role, false);
    };

    window.addEventListener("beforeunload", update);

    return () => {
      window.removeEventListener("beforeunload", update);
    };
  }, [chatId, role]);

  if (isOnline) {
    return <p>Online</p>;
  }

  return (
    <div className="text-gray-400 text-xs leading-[18px] font-medium">
      Offline
    </div>
  );
};

export default Online;
