import React, { useRef } from "react";

import clsx from "clsx";
import { toast } from "react-toastify";

import defaultPropertyImage from "../../../assets/images/property-image-horizontal.png";
import style from "../../../assets/css/homeowner/home.module.css";
import edit from "../../../assets/images/icons/editImage.svg";
import { useMutation } from "@tanstack/react-query";
import { uploadImage } from "../../api/homehub";
import { allowedProfileImageTypes } from "../../api/data";
import { useUser } from "../../provider/UserProvider";
import { HOMEOWNER } from "../../helpers/constants";
import { getStreetAddress } from "../../helpers/util";

const HomeDetail = ({ propertyData, propertyDataRefetch }) => {
  const propertyImageUploadRef = useRef(null);
  const { user } = useUser() || {};

  const handlePropertyImageUploadError = (event) => {
    event.target.src = defaultPropertyImage;
  };

  const openFileUpload = () => {
    propertyImageUploadRef.current.click();
  };

  const { mutate: imageUploadMutate } = useMutation(
    (data) => uploadImage(data, propertyData?.id),
    {
      onSuccess: (res) => {
        propertyDataRefetch();
        toast.success("Image updated successfully!");
      },
      onError: (err) => {
        toast.error(err.response.data.message);
      },
    }
  );

  const handlePropertyImageChange = (event) => {
    event.preventDefault();

    let fileObj = event.target.files[0];

    if (fileObj && !allowedProfileImageTypes.includes(fileObj?.type)) {
      toast.error("Please select a valid image.");
      return;
    }

    if (!fileObj) {
      return;
    }
    const formData = new FormData();
    formData.append("photo", fileObj);

    imageUploadMutate(formData, propertyData?.id);
  };

  return (
    <div className={clsx(style.homeCard, "maxMd:w-full")}>
      <div
        className={clsx("  relative ", {
          hovereffect: user?.role === HOMEOWNER,
        })}
      >
        {!propertyData?.property_detail_image ? (
          <img
            src={defaultPropertyImage}
            alt="home"
            className=" w-full h-[164px]    object-cover rounded-[5px] property_image "
            onError={handlePropertyImageUploadError}
          />
        ) : (
          <img
            src={propertyData?.property_detail_image}
            alt="home"
            className=" w-full h-[164px]    object-cover rounded-[5px] property_image "
            onError={handlePropertyImageUploadError}
          />
        )}

        {user?.role === HOMEOWNER && (
          <div onClick={openFileUpload} className=" outer_image">
            <div className=" group1 ">
              <img src={edit} alt="edit-img" className=" w-6 h-6" />
              <p className=" text-xs !text-white !m-0">Update Image</p>
            </div>

            <input
              ref={propertyImageUploadRef}
              className="hidden"
              type="file"
              onChange={handlePropertyImageChange}
              accept="image/*"
            />
          </div>
        )}
      </div>
      <div>
        {propertyData && (
          <>
            <h3>{getStreetAddress(propertyData?.address) || ""}</h3>
            <h5 className="!font-medium">
              {propertyData?.state} {propertyData?.zip_code}
            </h5>
          </>
        )}
        {!propertyData && <h3>{propertyData?.address}</h3>}
        <p>
          {propertyData?.number_of_beds || "0"} Bed |{" "}
          {propertyData?.number_of_baths || "0"} Bath |{" "}
          {propertyData?.square_feet || "0"} SqFt |{" "}
          {propertyData?.year_built || "0"} Built
        </p>
      </div>
    </div>
  );
};

export default HomeDetail;
