import clsx from "clsx";
import { debounce, isArray } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { getContactAddress } from "../../api/agent";
import { useQuery } from "@tanstack/react-query";

const ReApiDropDown = ({
  className = null,
  style = {},
  placeholderName,
  setFieldValue,
  isError,
  classNamePrefix,
  defaultvalue = null,
  module = "",
  setPosition = () => {},
  disabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    value: defaultvalue,
    label: defaultvalue?.title,
  });
  const [searchAddressKeyword, setSearchAddressKeyword] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [optionMessage, setOptionMessage] = useState(
    "Type 3 letters to see options"
  );

  const onInputChangeAddress = (newValue) => {
    setLocationData([]);

    setSearchAddressKeyword(newValue);
  };
  const onChangeAddress = (selectedOption) => {
    setSelectedOption(selectedOption);

    if (module === "add_property_address") {
      setPosition({
        lat: selectedOption?.value?.latitude,
        lng: selectedOption?.value?.longitude,
      });
    }
  };
  const { refetch: addressDataRefetch, isFetching: isFetchingAddressData } =
    useQuery(
      ["get-address"],
      () => getContactAddress(searchAddressKeyword, ["A"]),

      {
        enabled: searchAddressKeyword.length >= 3,
        refetchOnWindowFocus:false,
        onSuccess: (res) => {
          setLocationData(res?.data);
          res?.data?.length === 0 && setOptionMessage("No options available.");
        },
      }
    );

  const executeSearch = useRef(
    debounce(async () => {
      await addressDataRefetch();
    }, 300)
  ).current;

  useEffect(() => {
    if (searchAddressKeyword.length >= 3) {
      executeSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAddressKeyword]);

  useEffect(() => {
    if (module === "add_contact") {
      setFieldValue("address_data", selectedOption?.value);
      setLocationData([]);
    }

    if (module === "homeowner_signup") {
      setFieldValue(
        "address_data",
        selectedOption?.value ? selectedOption?.value : {}
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <>
      <Select
  onChange={onChangeAddress}
  options={
    isArray(locationData) &&
    locationData?.map((option) => ({
      value: option,
      label: option?.title,
    }))
  }
  defaultValue={
    defaultvalue && {
      value: defaultvalue,
      label: defaultvalue?.title,
    }
  }
  isDisabled={disabled}
  placeholder={placeholderName}
  isLoading={isFetchingAddressData}
  isClearable={true}
  onInputChange={onInputChangeAddress}
  isSearchable={true}
  classNamePrefix={classNamePrefix}
  style={style}
  className={clsx("w-full cursor-pointer", className, {
    "is-error rounded-lg": isError,
  })}
  noOptionsMessage={() => optionMessage}
  filterOption={null}  // Disable filtering of options
/>
    </>
  );
};

export default ReApiDropDown;
