import React from "react";

import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import withAuthentication from "../shared/helpers/withAuthentications";
import withoutAuthentication from "../shared/helpers/withoutAuthentication";
import SetPassword from "../shared/auth/set-password/SetPassword";
import NotFound from "../shared/components/not-found/NotFound";
import Unsubscribe from "../shared/components/unsubscribe/Unsubscribe";
import { UserProvider } from "../shared/provider/UserProvider";

import AuthenticatedApp from "../modules/AuthenticatedApp";
import InvestorLogin from "../modules/investors/auth/login/InvestorLogin";
import SellerSignUp from "../modules/sellers/auth/signup/SellerSignUp";
import SellerForgotPassword from "../modules/sellers/auth/forgot-password/SellerForgotPassword";
import SellerLogin from "../modules/sellers/auth/login/SellerLogin";
import SellerResetPassword from "../modules/sellers/auth/reset-password/SellerResetPassword";
import InvestorForgotPassword from "../modules/investors/auth/forgot-password/InvestorForgotPassword";
import InvestorResetPassword from "../modules/investors/auth/reset-password/InvestorResetPassword";
import AdminLogin from "../modules/admins/auth/login/AdminLogin";
import AdminForgotPassword from "../modules/admins/auth/forgot-password/AdminForgotPassword";
import AdminResetPassword from "../modules/admins/auth/reset-password/AdminResetPassword";
import AgentResetPassword from "../modules/agents/auth/reset-password/AgentResetPassword";
import AgentLogin from "../modules/agents/auth/login/AgentLogin";
import AgentForgotPassword from "../modules/agents/auth/forgot-passwod/AgentForgotPassword";
import AgentSignup from "../modules/agents/auth/signup/index";
import withAgentSignupLayoutHOC from "../shared/helpers/withAgentSignupLayoutHOC";
import PaymentCancel from "../modules/agents/auth/signup/PaymentCancel";
import PaymentSuccess from "../modules/agents/auth/signup/PaymentSuccess";
import InvestorSignUp from "../modules/investors/auth/signup";
import withoutAuthenticationHO from "../shared/helpers/withoutAuthenticationHO";
import withAuthPagesLayoutHOC from "../shared/helpers/withAuthPagesLayoutHOC";
import SignUp from "../home-owner/auth/signup/SignUp";
import Login from "../home-owner/auth/login/Login";
import ForgotPassword from "../home-owner/auth/forgot-password/ForgotPassword";
import ResetPassword from "../home-owner/auth/reset-password/ResetPassword";
import CheckPasswordModalWrapper from "../home-owner/auth/check-password-modal/CheckPasswordModalWrapper";
import checkURLCondition from "./checkURLCondition";

import HOPropertyDetailPublic from "../shared/components/homeowner-requests-details/HOPropertyDetailPublic";

const Authenticated = () => {
  return (
    <UserProvider>
      <AuthenticatedApp />
    </UserProvider>
  );
};

const UnAuthenticated = () => <Outlet />;

const UpsideRoutes = () => {
  return (
    <Routes>
      {<Route path="/*" element={withAuthentication(Authenticated)} />}

      {/* UnAuthenticated Routes */}
      <Route path="sellers" element={withoutAuthentication(UnAuthenticated)}>
        <Route index element={<Navigate to={"login"} />} />
        <Route path="signup" element={checkURLCondition(SellerSignUp)} />

        <Route path="login" element={checkURLCondition(SellerLogin)} />
        <Route path="forgot-password" element={<SellerForgotPassword />} />
        <Route path="set/password" element={<SetPassword />} />
        <Route
          path="reset/password/:email/:token"
          element={<SellerResetPassword />}
        />
      </Route>

      <Route path="buyers" element={withoutAuthentication(UnAuthenticated)}>
        <Route index element={<Navigate to={"login"} />} />
        <Route path="signup" element={checkURLCondition(InvestorSignUp)} />

        <Route path="login" element={checkURLCondition(InvestorLogin)} />
        <Route path="forgot-password" element={<InvestorForgotPassword />} />
        <Route path="set/password" element={<SetPassword />} />
        <Route
          path="reset/password/:email/:token"
          element={<InvestorResetPassword />}
        />
      </Route>

      <Route path="admin" element={withoutAuthentication(UnAuthenticated)}>
        <Route index element={<Navigate to={"login"} />} />
        <Route path="login" element={checkURLCondition(AdminLogin)} />
        <Route path="forgot-password" element={<AdminForgotPassword />} />
        <Route
          path="reset/password/:email/:token"
          element={<AdminResetPassword />}
        />
      </Route>

      <Route path="agents" element={withoutAuthentication(UnAuthenticated)}>
        <Route path="signup" element={withAgentSignupLayoutHOC(Outlet)}>
          <Route index element={checkURLCondition(AgentSignup)} />
        </Route>
        <Route path="signup/payment/success" element={<PaymentSuccess />} />
        <Route path="signup/payment/cancel" element={<PaymentCancel />} />
        <Route index element={<Navigate to={"login"} />} />
        <Route path="login" element={checkURLCondition(AgentLogin)} />
        {/* <Route path="global/login" element={<CommonAgentLogin />} /> */}
        <Route path="forgot-password" element={<AgentForgotPassword />} />
        <Route
          path="reset/password/:email/:token"
          element={<AgentResetPassword />}
        />
        <Route path="set/password" element={<SetPassword />} />
      </Route>

      <Route path="homehub" element={withoutAuthenticationHO(UnAuthenticated)}>
        <Route element={withAuthPagesLayoutHOC(Outlet)}>
          <Route index element={<Navigate to={"login"} />} />
          <Route path="signup" element={checkURLCondition(SignUp)} />
          <Route path="login" element={checkURLCondition(Login)} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route
            path="reset/password/:email/:token"
            element={<ResetPassword />}
          />
        </Route>
        <Route path="set/password" element={<CheckPasswordModalWrapper />} />
      </Route>

      <Route path="not-found" element={<NotFound />} />

      <Route path="*" element={<NotFound />} />

      <Route path="unsubscribe" element={<Unsubscribe />} />
      {
        <Route
          path="public/property/:id/details/:unique_hash"
          element={<HOPropertyDetailPublic />}
        />
      }
    </Routes>
  );
};

export default UpsideRoutes;
