import React from "react";
import ForgotPassword from "../../../../shared/auth/forgot-password/ForgotPassword";
import {
  forgotPassword,
  withoutTenantForgotPassword,
} from "../../../../shared/api/agent";
import {
  checkHostNameAndRedirect,
  isUpsideAppTenant,
} from "../../../../shared/helpers/util";

const AgentForgotPassword = () => {
  // Function for condition check
  const shouldRenderLogin = () => {
    return (
      checkHostNameAndRedirect() || !isUpsideAppTenant(window.location.href)
    );
  };

  return (
    <>
      {shouldRenderLogin() ? (
        <ForgotPassword onSubmit={forgotPassword} loginUrl="/agents/login" />
      ) : (
        <ForgotPassword
          onSubmit={withoutTenantForgotPassword}
          loginUrl="/agents/login"
        />
      )}
    </>
  );
};

export default AgentForgotPassword;
